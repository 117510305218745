@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --rem: 18;
  --page-width: 820px;
  --font-fancy: 'Amiri', courier;
  --font-body: 'Roboto', sans-serif;

  --color-hex-theme-light: #600C1B;
  --color-hsl-theme-light: 216, 47%, 48%;
  --color-hex-theme-dark: #122A51;
  --color-hsl-theme-dark: 218, 100%, 26%;
  --color-hex-lightgrey: #492c2c;
  --color-hsl-lightgrey: 0, 0%, 85%;;
  --color-hex-grey: #606060;
  --color-hsl-grey: 0, 0%, 38%;
  --color-hex-darkgrey: #2f2f2f;
  --color-hsl-darkgrey: 0, 0%, 18%;

  --color-hex-bg: #E6E5DB;
  --color-hex-font-light: var(--color-hex-bg);
}

@media (min-width: 740px){ .mobile-only { display: none!important; } }
@media (max-width: 739px){ .desktop-only { display: none!important; } }

html {
  font-size: 18px;
}

body {
  margin: 0;
  font-family: var(--font-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-hex-bg)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Text Style */
h1 {
  font-size: 2.5rem;
  font-family: var(--font-fancy);
  font-weight: 600;
}
h2 {
  font-size: 2rem;
  font-family: var(--font-fancy);
  font-weight: 600;
}
h3 {
  font-size: 1.3rem;
  font-family: var(--font-fancy);
}
h4 {
  font-size: 1.2rem;
}
h1, h2, h3, h4, h5 {
  line-height: 120%;
}
p,ul,li {
  font-size: 1rem;
  color: var(--color-hex-grey);
}
p, ul {
  padding-bottom: 1rem;
  line-height: 200%;
}
ul {
  list-style-type: '➕ ';
}
* > *:last-child {
  padding-bottom: 0;
}
/* margin-block override */
h1, h2, h3, h4, h5, p, ul {
  margin-block-start: 0;
  margin-block-end: 0;
}
img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: var(--color-hex-grey);
  cursor: pointer;
  border-bottom: 1px solid var(--color-hex-font-light);
  position: relative;
  z-index: 2;
}
a:visited {
  color: var(--color-hex-lightgrey);
  border-bottom: 1px solid var(--color-hex-grey);
}
a:hover {
  color: var(--color-hex-theme-light);
  border-bottom: 1px solid var(--color-hex-theme-light);
}

/* Header Style */
header {
  padding: 18px 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0px 5px -2px rgba(0,0,0,.5);

  display: grid;
  grid-template-areas: 'left center right';
  grid-template-columns: 190px 1fr 190px;
  justify-content: center;
  background-color: var(--color-hex-theme-light);
  color: var(--color-hex-font-light);
}

header > section {
  display: flex;
}
.header img {
  --dim: 60px;
  height: var(--dim);
  width: var(--dim);
}
.header-left {
  grid-area: left;
}
.header-left a {
  color: var(--color-hex-font-light);
  text-decoration: none;
  border-bottom: none!important;
}
.header-mid {
  grid-area: center;
}
.header-right {
  grid-area: right;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 739px){
  header {
    grid-template-areas: 'left center right';
    grid-template-columns: 190px 1fr auto;
    padding: 12px 16px;
    gap: 8px;
  }
  .header img {
    --dim: 42px;
  }
}



/* Nav */
header nav {
  width: 100%;
}
header nav a {
  margin: 0 1rem;
}
header nav a:first-of-type {
  margin-left: 2rem;
}
header nav a:last-of-type {
  margin-right: 2rem;
}
@media (min-width: 740px){
  header nav a {
    margin: 0 1rem;
  }
}
@media (max-width: 739px){
  header nav {
    text-align: center;
  }
}

nav a {
  text-decoration: none;
  color: var(--color-hex-font-light);
  border-bottom: 1px solid hsla(var(--color-hsl-lightgrey), 0);
  transition: .4s linear;
}
nav a:hover,
nav a:visited,
nav .active {
  color: var(--color-hex-font-light);
  border-bottom: 1px solid hsla(var(--color-hsl-lightgrey), 1);
}

/* Footer Style */
footer {
  background-image: linear-gradient(180deg, var(--color-hex-theme-dark), var(--color-hex-theme-dark));
  color: var(--color-hex-font-light);
  padding: 40px!important;
  font-size: 18px;
  font-weight: 300;
}
footer h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 16px 0 4px;
}
footer .grid.three-col .right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

/* Page Style */
.page-wrapper {
  min-height: calc(100dvh - 348px);
}
.page-width {
  width: 90%;
  max-width: var(--page-width);
  margin: 0 auto;
}
input[type=submit],
button, .button {
  background-image: linear-gradient(180deg, var(--color-hex-theme-dark), var(--color-hex-theme-dark));
  padding: 16px 64px;
  color: var(--color-hex-font-light)!important;
  border: none!important;
  box-shadow: 2px 2px 2px rgba(36,36,36,.5);
  transition: box-shadow .3s ease;
  vertical-align: middle;
  display: inline-block;
}
button:hover.active,
.button:hover {
box-shadow: 4px 4px 4px rgba(36,36,36,.5);
}



/* Grid template */
.grid {
  display: grid;
  gap: 32px;
}
.one-col {
  grid-template-columns: 1fr;
}
.two-col {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'left right';
}
.five-col {
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas: 'one two three four five';
  gap: 16px;
}
.three-col {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'left center right';
}

.grid.three-col .left,
.grid.two-col .left { grid-area: left; }
.grid.three-col .center { grid-area: center; }
.grid.three-col .right,
.grid.two-col .right { grid-area: right; }

@media (min-width: 740px){

  ul.ul-two-col {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  .grid.two-col {
    grid-template-areas: 'left right';
    
  }  

  

  
  .two-col,
  .desktop-two-col {
    grid-template-columns: 1fr 1fr;
  }
  .desktop-two-col.two-one {
    grid-template-columns: 2fr 1fr;
  }
}
@media (max-width: 739px){
  .grid {
    gap: 1em;
  }
  .grid.two-col {
    grid-template-areas: 'left' 'right';
  }
  header .grid.three-col {
    grid-template-areas: 'left center right';
  }
  footer .grid.three-col {
    grid-template-areas: 'center' 'left' 'right';
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .mobile-one-col {
    grid-template-columns: 1fr;
  }
  .mobile-two-col {
    grid-template-columns: 1fr 1fr;
  }
}

/* Input Style */
input {
  font-size: 1rem;
  height: 36px;
  margin: 5px auto;
  border-width: 1px;
  border-radius: 0px;
  cursor: text;
}

input[type=submit] {
  font-size: 1rem;
  line-height: 100%;
  text-transform: uppercase;
  border-radius: 0;
  height: 50px!important;
  border: #181717 solid 1px;
  position: relative;
  z-index: 900;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  background-color: #000;
  color:#fff;
  transition: 1s ease;
}

input[type=submit]:hover {
  background-color: #CCCCCC;
}
button {
  border-radius: 0px;
  border: 1px solid black;;
  background: transparent;
  cursor: pointer;
}

.header {
  line-height: 100%;
  padding-bottom: 1em;
}
.header small {
  font-weight: 300;
  font-size: clamp(16px, .45em, 24px);
  display: block;
  line-height: 120%;
}



.services-list-section {
padding: 80px 0!important;
background-image: linear-gradient(180deg, var(--color-hex-theme-light) 0%, var(--color-hex-theme-dark) 100%);
}
.section-container {
  --padding: 60px;
  width: calc(var(--page-width) + var(--padding)*2);
  padding: var(--padding);
  max-width: calc(100% - var(--padding)*2);
  margin: 0 auto;
  background-color: var(--color-hex-bg);
}



.section-container h2 {
  text-align: center;
  padding-bottom: 20px;
}
.section-container ul,
.section-container li {
font-size: 1.25rem;
}
@media (max-width: 739px){
  .section-container {
    --padding: 30px;
    width: calc(90% - var(--padding)*2);
    margin: 5%;
  }
  .section-container .grid {
    gap: 0;

  }

  .section-container .grid ul {
    padding-bottom: 0;
  }
  .section-container ul,
.section-container li {
font-size: 1rem;
}
}


/* Color pallet dark mode */
.inverse {
  color: var(--color-hex-font-light);;
  background-color: var(--color-hex-theme-light);
}


/* Scroll into view facde-in effect whe hide-item is removed */
.ref.see-item {
  opacity: 1;
  margin-top: 0;
  margin-bottom: 0;
  transition: 1s linear;
}
.ref.hide-item {
  --length: 6px;
  opacity: 0;
  margin-top: calc(var(--length)*-1);
  margin-bottom: var(--length);
}



/* Toggle visibility of Nav options */
@media (max-width: 799px) {
  .header-mid nav {
    display: none;
  }
  .header-right .phone-number {
    display: none;
  }
}
@media (min-width: 800px) {
  .header-right nav {
    display: none;
  }
}